import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export interface AppViewState {
  selectedMenu: string;
  selectedChannelId: string | null;
  showTalkRooom: boolean;
  // showDailyco: boolean;
}

export const AppMenus = {
  team: 'TEAM',
  task: 'TASK',
  mobileToday: 'MOBILE_TODAY',
  talkroom: 'TALKROOM',
  myStats: 'MY_STATS',
  teamStats: 'TEAMSTATS',
  teamTagAnalysis: 'TEAMTAGANALYSIS',
  reports: 'REPORTS',
  roadmaps: 'ROADMAPS',
  taskpool: 'TASKPOOL',
  userSetting: 'USER_SETTING',
  teamSetting: 'TEAMSETTING',
  // dailyco: 'DAILYCO',
};

@Module({ dynamic: true, store, name: 'app', namespaced: true })
class AppModule extends VuexModule implements AppViewState {
  public selectedMenu: string = AppMenus.team;
  public selectedChannelId: string | null = null;
  public showTalkRooom: boolean = false;
  public chatOpened: boolean = true;
  // public showDailyco: boolean = false;

  @Mutation
  public selectMenu(menu: string) {
    this.selectedMenu = menu;
    if (menu !== AppMenus.team) {
      this.selectedChannelId = null;
    }

    if (menu === AppMenus.talkroom) {
      this.showTalkRooom = true;
    }

    // if (menu === AppMenus.dailyco) {
    //   this.showDailyco = true;
    // }
  }

  @Mutation
  public selectChannel(channelId: string | null) {
    this.selectedChannelId = channelId;
  }

  @Mutation
  public setShowTalkroom(show: boolean) {
    this.showTalkRooom = show;
  }

  @Mutation
  public setChatOpened(state: boolean) {
    this.chatOpened = state;
  }

  // @Mutation
  // public setDailyco(show: boolean) {
  //   this.showDailyco = show;
  // }
}

export const appModule = getModule(AppModule);
