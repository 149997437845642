


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LocalDataService from '../../service/LocalDataServices';
import { AppMenus } from '../../store/viewModule/app/appModule';

@Component({
  components: {
  },
})
export default class TeamSettingScene extends Vue {

  public mounted() {
    const user = LocalDataService.getUser();
    const code = this.$route.query.code as string;
    if (user && code) {
      this.$router.push({
        name: 'app',
        params: {
          teamId: user.teamId,
        },
        query: {
          tab: 'notify',
          code,
          chatwork: 'true',
          menu: AppMenus.teamSetting,
        },
      });
    } else {
      this.$router.push({
        name: 'signin',
      });
    }
  }
}
